<template>
    <div id="menu">
        <b-card no-body>
            <b-tabs card>
                <b-tab title="Simplexe" active>
                    <b-list-group>
                        <b-list-group-item :href="'/solver'">
                            Solveur de simplexe
                        </b-list-group-item>
                        <b-list-group-item :href="'/generator'">
                            Générateur de simplexe
                        </b-list-group-item>
                    </b-list-group>
                </b-tab>
            </b-tabs>
        </b-card>
    </div>
</template>

<script>

export default {
    name: "Menu",
    data() {
        return {
        }
    }
}
</script>
