<template>
    <div id="generator">
        <b-card no-body>
            <b-tabs card v-model="tabIndex">
                <b-tab title="Paramètres" active>
                    <b-container>
                        <b-form-row>
                            <b-col>
                                <b-form-group id="field_n" description="Nombre de variables de décision"
                                    label="Variables de décision" label-for="input_n" label-cols="6" label-align="right"
                                    :invalid-feedback="invalidFeedback_input_n" :state="state_input_n">
                                    <b-form-input id="input_n" :state="state_input_n" v-model="n" trim />
                                </b-form-group>
                            </b-col>
                            <b-col>
                                <b-form-group id="field_m" description="Nombre de contraintes" label="Contraintes"
                                    label-for="input_m" label-cols="6" label-align="right"
                                    :invalid-feedback="invalidFeedback_input_m" :state="state_input_n">
                                    <b-form-input id="input_m" :state="state_input_m" v-model="m" trim />
                                </b-form-group>
                            </b-col>
                        </b-form-row>
                        <b-form-row>
                            <b-col>
                                <b-form-group id="field_s_min" description="Score minimum de l'instance générée"
                                    label="Scores minimum et maximum" label-for="input_s_min" label-cols="6"
                                    label-align="right" :invalid-feedback="invalidFeedback_input_s_min"
                                    :state="state_input_s_min">
                                    <b-form-input id="input_s_min" :state="state_input_s_min" v-model="s_min" trim />
                                </b-form-group>
                            </b-col>
                            <b-col>
                                <b-form-group id="field_s_max" description="Score maximum de l'instance générée"
                                    label-for="input_s_max" label-cols="6" label-align="right"
                                    :invalid-feedback="invalidFeedback_input_s_max" :state="state_input_s_max">
                                    <b-form-input id="input_s_max" :state="state_input_s_max" v-model="s_max" trim />
                                </b-form-group>
                            </b-col>
                        </b-form-row>
                        <b-form-row>
                            <b-col cols="3" align="right">
                                Pénalités (double clic pour modifier)
                            </b-col>
                            <b-col>
                                <b-table small striped responsive hover :items="items" :fields="fields"
                                    @row-dblclicked="clickRow">
                                    <template #cell(del)="row">
                                        <div style="text-align: right;">
                                            <b-btn variant="danger" @click="() => deleteRow(row.index)" size="sm">
                                                Supprimer
                                            </b-btn>
                                        </div>
                                    </template>
                                    <template slot="table-caption">
                                        <b-btn variant="info" @click="() => addRow()" size="sm">
                                            Ajouter une pénalité
                                        </b-btn>
                                    </template>
                                </b-table>
                            </b-col>
                        </b-form-row>
                        <b-form-row>
                            <b-col>
                                <b-form-group id="field_p" description="Pénalité pour les autres fractions"
                                    label="Pénalité (autres)" label-for="input_p" label-cols="6" label-align="right"
                                    :invalid-feedback="invalidFeedback_input_p" :state="state_input_p">
                                    <b-form-input id="input_p" :state="state_input_p" v-model="p" trim />
                                </b-form-group>
                            </b-col>
                            <b-col>
                                <b-form-group id="field_i" description="Nombre d'itérations du simplexe"
                                    label="Itérations" label-for="input_i" label-cols="6" label-align="right"
                                    :invalid-feedback="invalidFeedback_input_i" :state="state_input_i">
                                    <b-form-input id="input_i" :state="state_input_i" v-model="i" trim />
                                </b-form-group>
                            </b-col>
                        </b-form-row>
                        <b-form-row>
                            <b-col>
                                <b-form-group id="field_iter" description="Nombre d'instances à générer"
                                    label="Nombre de modèles" label-for="input_iter" label-cols="6" label-align="right"
                                    :invalid-feedback="invalidFeedback_input_iter" :state="state_input_iter">
                                    <b-form-input id="input_iter" :state="state_input_iter" v-model="nbiters" trim />
                                </b-form-group>
                            </b-col>
                            <b-col></b-col>
                        </b-form-row>
                        <b-modal id="modal1" ref="penal_modal" title="Modifier" @ok="handleModalOk"
                            @cancel="handleModalCancel">
                            <form ref="form" @submit.stop.prevent="handleSubmit">
                                <b-form-group id="field_fm" description="Dénominateur de la fraction"
                                    label="Dénominateur" label-for="input_fm" label-cols="6"
                                    :invalid-feedback="invalidFeedback_input_fm" :state="state_input_fm">
                                    <b-form-input id="input_fm" :state="state_input_fm" v-model="fm" trim />
                                </b-form-group>
                                <b-form-group id="field_pm" description="Pénalité " label="Pénalité"
                                    label-for="input_pm" label-cols="6" :invalid-feedback="invalidFeedback_input_pm"
                                    :state="state_input_pm">
                                    <b-form-input id="input_pm" :state="state_input_pm" v-model="pm" trim />
                                </b-form-group>
                            </form>
                        </b-modal>
                        <b-btn variant="info" @click="() => sendData()" size="sm" :disabled="state_send">
                            Envoyer
                        </b-btn>
                    </b-container>
                </b-tab>
                <b-tab :title="(nbiters > 1) ? 'Modèles générés' : 'Modèle généré'">
                    <b-container>
                        <b-row v-if="results">
                            <b-col cols="4" v-for="(entry, index) in results" :key="index">
                                <b-card :title="'Modèle n° ' + (Math.trunc(index) + 1)">
                                    <b-card-text>
                                        {{ entry.obj }}
                                    </b-card-text>
                                    <b-card-text v-html="entry.constr">
                                    </b-card-text>
                                    <b-card-text class="small text-muted">
                                        Score : {{ entry.score }}
                                    </b-card-text>
                                </b-card>
                            </b-col>
                        </b-row>
                    </b-container>
                </b-tab>
            </b-tabs>
        </b-card>
    </div>
</template>

<script>
import axios from 'axios';

export default {
    name: "Generator",
    data() {
        return {
            tabIndex: 0,
            n: 2,
            m: 3,
            s_min: 10,
            s_max: 50,
            i: 2,
            p: 999999,
            items: [
                { 'd': 2, 'p': 1 },
                { 'd': 3, 'p': 2 },
                { 'd': 4, 'p': 3 },
                { 'd': 5, 'p': 5 },
                { 'd': 6, 'p': 10 },
                { 'd': 7, 'p': 20 },
                { 'd': 8, 'p': 10 },
                { 'd': 9, 'p': 20 }
            ],
            nbiters: 1,
            fields: [
                { key: 'd', label: 'Dénominateur' },
                { key: 'p', label: 'Pénalité' },
                { key: 'del', label: ' ' }
            ],
            fm: null,
            pm: null,
            item: null,
            new: false,
            results: null,
        }
    },
    methods: {
        clickRow: function (item) {
            this.new = false;
            this.editRow(item);
        },
        editRow: function (item) {
            this.fm = item.d;
            this.pm = item.p;
            this.item = item;
            this.$refs.penal_modal.show();
        },
        addRow: function () {
            let item = { 'd': 1, 'p': 1 };
            this.new = true;
            this.editRow(item);
        },
        handleModalOk: function (bvModalEvent) {
            // Prevent modal from closing
            bvModalEvent.preventDefault()
            // Trigger submit handler
            this.handleSubmit()
        },
        handleSubmit: function () {
            if (!this.state_input_fm || !this.state_input_pm) {
                return;
            }
            this.item.d = this.fm;
            this.item.p = this.pm;
            if (this.new) {
                this.items.push(this.item);
                this.items.sort((a, b) => {
                    if (a.d > b.d)
                        return 1;
                    if (a.d < b.d)
                        return -1;
                    else
                        return a.p - b.p
                })
            }
            this.new = false;
            this.$nextTick(() => {
                this.$bvModal.hide('modal1')
            })
        },
        deleteRow: function (idx) {
            this.items.splice(idx, 1);
        },
        handleModalCancel: function () {
            this.new = false;
        },
        checkItems: function (n) {
            for (let i = 0; i < this.items.length; i++) {
                if (this.items[i].d.toString() === n) {
                    return this.items[i];
                }
            }
            return false;
        },
        sendData: function () {
            let data = {};
            data['p'] = Math.trunc(this.p);
            data['n'] = Math.trunc(this.n);
            data['m'] = Math.trunc(this.m);
            data['s_min'] = Math.trunc(this.s_min);
            data['s_max'] = Math.trunc(this.s_max);
            data['i'] = Math.trunc(this.i);
            data['f'] = this.items;
            data['nbiters'] = Math.trunc(this.nbiters);
            let promise = axios.post('/api/generate', data);
            promise.then(response => {
                this.checkResponse(response);
            }).catch(error => {
                console.log(error);
            });
        },
        checkResponse: function (response) {
            if (response.statusText === 'OK') {
                let results = [];
                let nb_iter = response.data.length;
                for (let iter = 0; iter < nb_iter; iter++) {
                    let entry = {};
                    entry.score = response.data[iter].score;
                    let a = response.data[iter].a.slice();
                    let b = response.data[iter].b.slice();
                    let c = response.data[iter].c.slice();
                    let obj = 'Max ';
                    obj += this.formatLinePlain(a);
                    let constr = 's.c. ' + this.formatLinePlain(b[0]) + ' <= ' + c[0];
                    for (let i = 1; i < b.length; i++) {
                        constr += '<br>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;' + this.formatLinePlain(b[i]) + ' <= ' + c[i];
                    }
                    entry.obj = obj;
                    entry.constr = constr;
                    results.push(entry);
                }
                this.results = results;

                this.tabIndex = 1;
            }
        },
        formatLinePlain: function (row) {
            let target = '';
            for (let i = 0; i < row.length; i++) {
                if (i > 0 && (row[i][0] !== '-')) {
                    target += '+ '
                }
                target += row[i];
                target += ' x' + (i + 1) + ' ';
            }
            return target;
        }
    },
    computed: {
        state_input_n: function () {
            return !isNaN(Math.trunc(this.n));
        },
        invalidFeedback_input_n: function () {
            if (isNaN(Math.trunc(this.n))) {
                return "Il faut saisir un nombre"
            }
        },
        state_input_m: function () {
            return !isNaN(Math.trunc(this.m));
        },
        invalidFeedback_input_m: function () {
            if (isNaN(Math.trunc(this.m))) {
                return "Il faut saisir un nombre"
            }
        },
        state_input_s_min: function () {
            return !isNaN(Math.trunc(this.s_min));
        },
        invalidFeedback_input_s_min: function () {
            if (isNaN(Math.trunc(this.s_min))) {
                return "Il faut saisir un nombre"
            }
        },
        state_input_s_max: function () {
            return !isNaN(Math.trunc(this.s_max));
        },
        invalidFeedback_input_s_max: function () {
            if (isNaN(Math.trunc(this.s_max))) {
                return "Il faut saisir un nombre"
            }
        },
        state_input_p: function () {
            return !isNaN(Math.trunc(this.p));
        },
        invalidFeedback_input_p: function () {
            if (isNaN(Math.trunc(this.p))) {
                return "Il faut saisir un nombre"
            }
        },
        state_input_i: function () {
            return !isNaN(Math.trunc(this.i));
        },
        invalidFeedback_input_i: function () {
            if (isNaN(Math.trunc(this.i))) {
                return "Il faut saisir un nombre"
            }
        },
        state_input_fm: function () {
            if (this.checkItems(this.fm) && this.new) {
                return false;
            }
            if (this.fm === null || this.fm.length === 0) {
                return false;
            }
            return !isNaN(Math.trunc(this.fm));
        },
        invalidFeedback_input_fm: function () {
            if (this.checkItems(this.fm) && this.new) {
                return "Ce  dénominateur est déjà défini";
            }
            if (isNaN(Math.trunc(this.fm)) || this.fm === null || this.fm.length === 0) {
                return "Il faut saisir un nombre"
            }
        },
        state_input_pm: function () {
            if (this.pm === null || this.pm.length === 0) {
                return false;
            }
            return !isNaN(Math.trunc(this.pm));
        },
        invalidFeedback_input_pm: function () {
            if (isNaN(Math.trunc(this.pm)) || this.pm === null || this.pm.length === 0) {
                return "Il faut saisir un nombre"
            }
        },
        state_input_iter: function () {
            return !isNaN(Math.trunc(this.nbiters));
        },
        invalidFeedback_input_iter: function () {
            if (isNaN(Math.trunc(this.nbiters))) {
                return "Il faut saisir un nombre"
            }
        },
        state_send: function () {
            return !(this.state_input_n && this.state_input_m && this.state_input_s_min && this.state_input_s_max &&
                this.state_input_p && this.state_input_i && this.state_input_iter);
        }
    },
}
</script>
