import Vue from 'vue'
import Router from 'vue-router'
import Menu from '../pages/Menu.vue'
import Solver from '../pages/Solver.vue'
import Generator from '../pages/Generator.vue'

// noinspection JSUnresolvedFunction
Vue.use(Router);

export default new Router({
    mode: 'history',
    routes: [
        {
            path: '/',
            name: 'Menu',
            component: Menu
        },
        {
            path: '/solver',
            name: 'Solver',
            component: Solver
        },
        {
            path: '/generator',
            name: 'Generator',
            component: Generator
        }
    ]
})
