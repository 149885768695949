import Vue from 'vue'
import router from './router'
import BootstrapVue from "bootstrap-vue"
import App from './App.vue'
// noinspection NpmUsedModulesInstalled
import { BreakpointPlugin } from "vue-breakpoint";

Vue.use(BootstrapVue);
Vue.use(router);
Vue.use(BreakpointPlugin);

new Vue({
    el: '#app',
    router,
    template: '<App/>',
    components: { App }
});
