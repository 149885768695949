<template>
    <div id="solver">
        <b-card no-body>
            <b-tabs card v-model="tabIndex">
                <b-tab title="Modèle à résoudre" active>
                    <b-container>
                        <b-row>
                            <b-col cols="4">
                                Nombre de variables
                            </b-col>
                            <b-col cols="2">
                                <b-form-input v-model="n"></b-form-input>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col cols="4">
                                Nombre de contraintes
                            </b-col>
                            <b-col cols="2">
                                <b-form-input v-model="m"></b-form-input>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col cols="4">
                                Autoriser les cycles ?
                            </b-col>
                            <b-col cols="2">
                                <b-form-checkbox v-model="cycle_ok"></b-form-checkbox>
                            </b-col>
                        </b-row>
                        <b-row>
                            &nbsp;
                        </b-row>
                        <b-row>
                            <b-col cols="4">
                                Fonction objectif
                            </b-col>
                            <b-col>
                                <table class="table">
                                    <tr>
                                        <th v-for="h in headers" :id="h">{{ h }}</th>
                                    </tr>
                                    <tr>
                                        <td v-for="(x, index) in a" :key="index">
                                            <b-form-input v-model="a[index]"></b-form-input>
                                        </td>
                                    </tr>
                                </table>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col cols="4">
                                Contraintes
                            </b-col>
                            <b-col>
                                <table class="table">
                                    <tr>
                                        <th id="L"></th>
                                        <th v-for="h in headers" :id="h">{{ h }}</th>
                                        <th id="Z"></th>
                                        <th id="cst">Cst</th>
                                    </tr>
                                    <tr v-for="(row, index) in b" :key="index">
                                        <td>
                                            {{ index + 1 }}
                                        </td>
                                        <td v-for="(x, idx) in row" :key="idx">
                                            <b-form-input v-model="b[index][idx]"></b-form-input>
                                        </td>
                                        <td>
                                            &lt;
                                        </td>
                                        <td>
                                            <b-form-input v-model="c[index]"></b-form-input>
                                        </td>
                                    </tr>
                                </table>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col cols="2">
                                <b-btn variant="info" @click="() => sendData()">
                                    Envoyer
                                </b-btn>
                            </b-col>
                            <b-col>
                                <div class="alert alert-danger" role="alert" v-if="error">
                                    {{ error }}
                                </div>
                            </b-col>
                        </b-row>
                    </b-container>
                </b-tab>
                <b-tab title="Solution">
                    <b-row v-if="results">
                        <b-col cols="6">
                            <b-card title="Tableaux" v-if="plain">
                                <b-card-text v-for="(tab, index) in plainData" :key="index" v-html="tab">
                                </b-card-text>
                            </b-card>
                        </b-col>
                        <b-col>
                            <b-card title="Tableaux (LaTeX)" v-if="latex">
                                <b-card-text v-for="(tab, index) in latexData" :key="index" v-html="tab">
                                </b-card-text>
                            </b-card>
                        </b-col>
                    </b-row>
                </b-tab>
            </b-tabs>
        </b-card>
    </div>
</template>

<script>
import axios from 'axios';

export default {
    name: "Solver",
    data() {
        return {
            tabIndex: 1,
            results: false,
            plain: false,
            plainData: false,
            latex: false,
            latexData: false,
            n: 2,
            m: 3,
            a: [],
            b: [],
            c: [],
            cycle_ok: false,
            error: null,
        }
    },
    methods: {
        sendData: function () {
            let data = {
                'a': this.a,
                'b': this.b,
                'c': this.c,
                'cycle_ok': this.cycle_ok
            };
            this.error = null;
            let promise = axios.post('/api/solve', data);
            promise.then(response => {
                this.checkResponse(response);
            }).catch(error => {
                if (error.response)
                    this.checkResponse(error.response);
                else
                    console.log(error);

            });
        },
        checkResponse: function (response) {
            if (response.status === 200) {
                this.results = response.data;
                let T = response.data.T.slice();
                let pivots = response.data.pivots.slice();
                let cycle = response.data.cycle;
                let v_e = T[0].length - 1;
                let v_d = T[0][0].length - 2 - (v_e);
                this.doPlain(T, pivots, v_d, v_e, cycle);
                this.doLatex(T, pivots, v_d, v_e, cycle);
                this.tabIndex = 1;
            } else if (response.status === 400) {
                this.error = 'Erreur : ' + response.data.erreur;
            } else {
                console.log(response);
            }
        },
        doPlain: function (T, pivots, v_d, v_e, cycle) {
            this.plainData = [];
            for (let i = 0; i < pivots.length; i++) {
                let iter_entry = 'Itération ' + i + '<table class="table table-bordered"><tr><th></th>';
                for (let c = 0; c < v_d; c++) {
                    iter_entry += '<th>x' + (c + 1) + '</th>';
                }
                for (let c = 0; c < v_e; c++) {
                    iter_entry += '<th>s' + (c + 1) + '</th>';
                }
                iter_entry += '<th>Z</th><th>Cst</th>';
                iter_entry += '</tr>';
                for (let j = 0; j < T[i].length; j++) {
                    let pivot = (j === pivots[i][0]);
                    iter_entry += this.formatLinePlain(T[i][j], j, pivot, pivots[i][1]);
                }
                iter_entry += '</table>';
                iter_entry += '<br> Ligne pivot : ' + pivots[i][0] + '<br>';
                iter_entry += 'Colonne pivot : ' + pivots[i][1] + '<br>';
                if (cycle > 0 && cycle === i) iter_entry += 'Cycle détecté !<br>';
                this.plainData.push(iter_entry);
            }
            let entry = 'Tableau final' + '<table class="table table-bordered"><tr><th></th>';
            for (let c = 0; c < v_d; c++) {
                entry += '<th>x' + (c + 1) + '</th>';
            }
            for (let c = 0; c < v_e; c++) {
                entry += '<th>s' + (c + 1) + '</th>';
            }
            entry += '<th>Z</th><th>Cst</th>';
            entry += '</tr>';
            let l = T.length - 1;
            for (let j = 0; j < T[l].length; j++) {
                entry += this.formatLinePlain(T[l][j], j, false);
            }
            entry += '</table>';
            this.plainData.push(entry);
            this.plain = true;
        },
        doLatex: function (T, pivots, v_d, v_e, cycle) {
            this.latexData = [];

            for (let i = 0; i < pivots.length; i++) {
                let iter_entry = 'Itération ' + i + '\\\\<br>';
                iter_entry += '\\resizebox{\\textwidth}{!}{%<br>';
                iter_entry += '\\begin{tabular}{l|';
                for (let v = 0; v < v_d + v_e; v++) {
                    iter_entry += 'l';
                }
                iter_entry += 'l|l}<br> ';
                for (let c = 0; c < v_d; c++) {
                    iter_entry += '& $x_' + (c + 1) + '$ ';
                }
                for (let c = 0; c < v_e; c++) {
                    iter_entry += '& $s_' + (c + 1) + '$';
                }
                iter_entry += '& $Z$ & $Cst$ \\\\\\hline<br>';
                for (let j = 0; j < T[i].length; j++) {
                    let pivot = (j === pivots[i][0]);
                    iter_entry += this.formatLineLatex(T[i][j], j, pivot, pivots[i][1]);
                }
                iter_entry += '\\end{tabular}\\\\<br>';
                iter_entry += '}';
                iter_entry += '<br> Ligne pivot : ' + pivots[i][0] + '\\\\<br>';
                if (cycle > 0 && cycle === i) {
                    iter_entry += 'Colonne pivot : ' + pivots[i][1] + '\\\\<br>';
                    iter_entry += 'Cycle détecté !<br>';
                } else {
                    iter_entry += 'Colonne pivot : ' + pivots[i][1] + '<br>';
                }

                this.latexData.push(iter_entry);
            }

            let entry = 'Tableau final\\\\<br>';
            entry += '\\resizebox{\\textwidth}{!}{%<br>';
            entry += '\\begin{tabular}{l|';
            for (let v = 0; v < v_d + v_e; v++) {
                entry += 'l';
            }
            entry += 'l|l}<br> ';
            for (let c = 0; c < v_d; c++) {
                entry += '& $x_' + (c + 1) + '$ ';
            }
            for (let c = 0; c < v_e; c++) {
                entry += '& $s_' + (c + 1) + '$';
            }
            entry += '& $Z$ & $Cst$ \\\\\\hline<br>';
            let l = T.length - 1;
            for (let j = 0; j < T[l].length; j++) {
                entry += this.formatLineLatex(T[l][j], j, false);
            }
            entry += '\\end{tabular}\\\\<br>';
            entry += '}';
            this.latexData.push(entry);
            this.latex = true;

        },
        formatLinePlain: function (row, num, pivot = false, col_pivot = false) {
            let target = '';
            if (pivot)
                target += '<tr class="table-info">';
            else
                target += '<tr>';
            target += '<th scope="row">L' + num + '</th>';
            for (let i = 0; i < row.length; i++) {
                if (i === col_pivot - 1)
                    target += '<td class="table-info">' + row[i] + '</td>';
                else
                    target += '<td>' + row[i] + '</td>';
            }
            target += '</tr>';
            return target;
        },
        formatLineLatex: function (row, num, pivot = false, col_pivot = false) {
            let target = '';
            if (pivot)
                target += '\\textcolor[rgb]{1,0,0}{';
            target += '$L_' + num + '$';
            if (pivot)
                target += '}';
            for (let i = 0; i < row.length; i++) {
                if (i === col_pivot - 1 || pivot)
                    target += ' & \\textcolor[rgb]{1,0,0}{' + row[i] + '}';
                else
                    target += ' & ' + row[i];
            }
            target += '\\\\<br>';
            return target;
        }
    },
    computed: {
        headers: {
            get: function () {
                let headers = [];
                for (let i = 0; i < this.n; i++) {
                    headers.push('x' + (i + 1));
                }
                let gap = this.n - this.a.length;
                let bgap = this.m - this.b.length;
                if (bgap > 0) { // On a ajouté des contraintes
                    for (let i = 0; i < bgap; i++) {
                        let row = [];
                        for (let j = 0; j < this.a.length; j++) {
                            row.push(0);
                        }
                        this.b.push(row.slice());
                        this.c.push(0);
                    }
                } else {
                    for (let i = 0; i < -bgap; i++) {
                        this.b.pop();
                        this.c.pop();
                    }
                }
                if (this.a.length < this.n) { // On a ajouté des variables
                    for (let i = 0; i < gap; i++) {
                        this.a.push(0);
                        for (let j = 0; j < this.b.length; j++) {
                            this.b[j].push(0);
                        }
                    }
                } else { // On en a retiré
                    for (let i = 0; i < -gap; i++) {
                        this.a.pop();
                        for (let j = 0; j < this.b.length; j++) {
                            this.b[j].pop();
                        }
                    }
                }
                return headers;
            }
        }
    }
}
</script>
